import { Layout, SEO } from "components"
import React from "react"
import "./case-studies.scss"
import useGetAllBlogsData from "hooks/useGetAllBlogsData"
import SmartLink from "components/SmartLink/SmartLink"
import { GatsbyImage } from "gatsby-plugin-image"
import { formatDate } from "utils/dateFunctions"

const CaseStudies = () => {
  const data = useGetAllBlogsData()
  const [first, ...rest] = [...(data ?? [])]

  return (
    <Layout>
      <SEO
        title="Teamble Case Studies"
        description="Discover real-world success stories of how companies improved collaboration and workplace culture with Teamble. Learn from data-driven case studies and proven strategies."
      />
      <div className="CaseStudies">
        <div className="banner-section">
          <div className="title">
            Explore our clients’ feedback transformation stories
          </div>
          <div className="bannerImage">
            <div className="content">
              {typeof first?.node?.data?.published === "string" && (
                <div className="date">
                  {formatDate(first?.node?.data?.published)}
                </div>
              )}
              {first?.node?.data?.body1?.[0]?.primary?.case_study_title
                ?.text && (
                <div className="card-title">
                  {first?.node?.data?.body1?.[0]?.primary?.case_study_title
                    ?.text ?? ""}
                </div>
              )}
              {first?.node?.data?.body1?.[0]?.primary?.content?.text && (
                <div className="subtitle">
                  {first?.node?.data?.body1?.[0]?.primary?.content?.text}
                </div>
              )}
              <SmartLink className="link" url={`${first?.node?.uid}`}>
                Read whole story
              </SmartLink>
            </div>
            <GatsbyImage
              className="image"
              alt={first?.node?.data?.body1?.[0]?.primary?.feature_image?.alt}
              image={
                first?.node?.data?.body1?.[0]?.primary?.feature_image
                  ?.gatsbyImageData
              }
              objectFit="cover"
            />
          </div>
        </div>
        <div className="case-studies-list">
          {rest?.map((item: any, index: number) => (
            <div key={index} className="card">
              <GatsbyImage
                className="image"
                alt={item?.node?.data?.body1?.[0]?.primary?.feature_image?.alt}
                image={
                  item?.node?.data?.body1?.[0]?.primary?.feature_image
                    ?.gatsbyImageData
                }
                objectFit="cover"
              />
              <div className="content">
                <div>
                  {typeof item?.node?.data?.published === "string" && (
                    <div className="date">
                      {formatDate(item?.node?.data?.published)}
                    </div>
                  )}
                  {item?.node?.data?.body1?.[0]?.primary?.case_study_title
                    ?.text && (
                    <div className="card-title">
                      {item?.node?.data?.body1?.[0]?.primary?.case_study_title
                        ?.text ?? ""}
                    </div>
                  )}
                  {item?.node?.data?.body1?.[0]?.primary?.content?.text && (
                    <div className="subtitle">
                      {item?.node?.data?.body1?.[0]?.primary?.content?.text}
                    </div>
                  )}
                </div>
                <SmartLink url={`${item?.node?.uid}`}>
                  <div className="link">Read whole story</div>
                </SmartLink>
              </div>
            </div>
          ))}
        </div>
      </div>
    </Layout>
  )
}

export default CaseStudies
